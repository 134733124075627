<template>
  <b-card
    v-if="showGamification"
    class="overflow-x-auto"
    no-body
  >
    <p class="p-1 m-0">
      Ambil tantangan buat selesaikan <b>pickup pertamamu</b> dalam <b>24 jam</b> dan dapatin saldo ongkir <b>50.000</b></p>
    <div class="flex p-1">
      <img
        src="https://storage.googleapis.com/komerce/assets/icons/gramification-verify.svg"
        :class="gamificationStep.isVerification ? '' : 'grayscale'"
      >
      <img
        src="https://storage.googleapis.com/komerce/assets/icons/gramification-expedition.svg"
        :class="gamificationStep.isExpeditionSetting ? '' : 'grayscale'"
      >
      <img
        src="https://storage.googleapis.com/komerce/assets/icons/gramification-warehouse.svg"
        :class="gamificationStep.isWarehouseSetting ? '' : 'grayscale'"
      >
      <img
        src="https://storage.googleapis.com/komerce/assets/icons/gramification-product.svg"
        :class="gamificationStep.isProductUpload ? '' : 'grayscale'"
      >
      <img
        src="https://storage.googleapis.com/komerce/assets/icons/gramification-order.svg"
        :class="gamificationStep.isAddOrder ? '' : 'grayscale'"
      >
      <img
        src="https://storage.googleapis.com/komerce/assets/icons/gramification-pickup.svg"
        :class="gamificationStep.isPickupRequest ? '' : 'grayscale'"
      >
      <div
        v-if="durationGramification !== ''"
        class="flex items-center ml-1"
      >
        <img
          src="https://storage.googleapis.com/komerce/assets/svg/thropy-selesai.svg"
          class="mr-1"
        >
        <div>
          <p class="mb-[5px] min-w-[130px]">
            <b>50.000</b> KomPoints</p>
          <b-button
            variant="warning"
            size="sm"
            :disabled="!gamificationStep.isFinish || loadingClaim"
            block
            pill
            @click="claimGamification()"
          >
            <b-spinner
              v-if="loadingClaim"
              class="mr-[6px]"
              small
            />
            Claim</b-button>
        </div>
      </div>
    </div>
    <div
      v-if="durationGramification !== ''"
      class="flex h-[14px] w-100 bg-[#FCBEBE] my-[30px]"
    >
      <div class="absolute mt-[-15px] left-1/2 transform -translate-x-1/2 flex bg-danger p-[10px] rounded-full text-white">
        <img src="@/assets/images/icons/countdown.svg">
        <p class="mb-0 my-auto">
          {{ durationGramification }}</p>
      </div>
    </div>
    <b-modal
      ref="modalClaimSuccess"
      hide-header-close
      hide-header
      hide-footer
      centered
    >
      <div class="p-1 text-center">
        <p class="text-2xl font-semibold">
          Yeay, Selamat!!!</p>
        <p>Kamu mendapatkan <b>50.000</b> KomPoints Gunakan point untuk menghemat saldo kamu dalam bertransaksi di Komship</p>
        <lottie-animation
          path="animation/claim__success__animation.json"
          :width="300"
          :height="300"
        />
        <b-button
          variant="warning"
          block
          @click="$router.go()"
        >Selanjutnya</b-button>
      </div>
    </b-modal>
  </b-card>
</template>
<script>
import { mapState } from 'vuex'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'

export default {
  components: { LottieAnimation },
  data() {
    return {
      durationGramification: '',
      countdownGramification: null,
      loadingClaim: false,
      showGamification: true,
    }
  },
  computed: {
    ...mapState('dashboard', [
      'gamificationStep',
      'gamificationExpired',
    ]),
  },
  mounted() {
    this.startCountdownGramification()
    this.endGamification()
  },
  methods: {
    async claimGamification() {
      this.loadingClaim = true
      try {
        const response = await this.$http_komship.post('v1/gamifaction/claim')
        const { code } = response.data
        if (code === 200) this.$refs.modalClaimSuccess.show()
        this.loadingClaim = false
      } catch (error) {
        console.error(error.response)
        this.loadingClaim = false
      }
    },
    startCountdownGramification() {
      const now = this.$moment()
      const expired = this.$moment(this.gamificationExpired)
      const duration = this.$moment.duration(expired.diff(now))
      const isDurationValid = duration.asMilliseconds() > 0
      if (isDurationValid) {
        this.updateDurationGramification(now, this.gamificationExpired)
        this.countdownGramification = setInterval(() => {
          this.updateDurationGramification()
        }, 1000)
      }
    },
    updateDurationGramification() {
      const now = this.$moment()
      const expired = this.$moment(this.gamificationExpired)
      const diff = this.$moment.duration(expired.diff(now))
      const hours = Math.floor(diff.asHours())
      const minutes = Math.floor(diff.asMinutes()) % 60
      const seconds = Math.floor(diff.asSeconds()) % 60
      const isDurationValid = diff.asMilliseconds() > 0
      if (isDurationValid) {
        this.durationGramification = `${this.addLeadingZero(hours)} Jam : ${this.addLeadingZero(minutes)} Menit : ${this.addLeadingZero(seconds)} Detik`
      } else {
        this.durationGramification = ''
        clearInterval(this.countdownGramification)
      }
    },
    addLeadingZero(value) {
      return value.toString().padStart(2, '0')
    },
    endGamification() {
      if (this.gamificationStep.isClaim) {
        this.showGamification = false
      } else if (this.durationGramification === '') {
        this.showGamification = false
      }
    },
  },
}
</script>
